import { navigate } from 'gatsby';
import { createUseStyles } from 'react-jss';
import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import ImageChefHat from '../components/images/ImageChefHat';
import ImageChoppingBoard from '../components/images/ImageChoppingBoard';
import ImageStaffLocation from '../components/images/ImageStaffLocation';
import ImageMoneySafe from '../components/images/ImageMoneySafe';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const useStyles = createUseStyles(theme => ({
  betaBannerContainer: {
    height: '180px',
    overflow: 'hidden',
    position: 'absolute',
    top: '0',
    left: '0',
    right: 'auto',
    width: '180px',
  },
  betaBanner: {
    backgroundColor: theme.colorSecondary,
    boxShadow: '0 0 10px 0 #888888',
    height: 'auto',
    left: '-85px',
    overflow: 'hidden',
    padding: '2px 0',
    position: 'absolute',
    right: 'auto',
    top: '40px',
    transform: 'rotate(-45deg)',
    width: 'auto',
  },
  betaBannerText: {
    color: 'white',
    border: '1px solid white',
    display: 'block',
    height: '100%',
    padding: '8px 80px',
    textAlign: 'center',
    textShadow: '0 0 5px #666',
    whiteSpace: 'nowrap',
  },
  choppingBoard: {
    flex: '1',
  },
  choppingBoardContainer: {
    display: 'flex',
    flex: '1',
    maxHeight: '450px',
    overflow: 'hidden',
    position: 'relative',
  },
  choppingBoardTextContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
  choppingBoardText: {
    color: 'white',
    fontSize: theme.fontSizeLarge,
    margin: '1em',
    maxWidth: '550px',
    textAlign: 'center',
    textShadow: '0 0 5px #666',
  },
  featuresContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  featureCardContent: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'space-between',
    lineHeight: '1.5',
  },
  featureIcon: {
    alignSelf: 'center',
    height: '100px',
    width: '100px',
  },
  header: {
    fontSize: theme.fontSizeMedium,
    fontWeight: '400',
    margin: '1rem 1rem 0 1rem',
    textAlign: 'center',
  },
  headerSubtitle: {
    color: theme.colorSubtitle,
    fontSize: theme.fontSizeMedium,
    fontWeight: '400',
    margin: '2rem',
    textAlign: 'center',
  },
  includedToolsInner: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: '100px',
    marginTop: '1em',
    alignItems: 'stretch',
  },
  includedToolsItem: {
    height: '1.5em',
    margin: '0',
  },
  '@media (max-width: 900px)': {
    includedToolsInner: {
      height: 'unset',
    },
  },
}));

const styleFeatureCard = {
  display: 'flex',
  flex: '1 1 200px',
  flexDirection: 'column',
  width: '40%',
};

const styleIncludedToolsCard = {
  ...styleFeatureCard,
  flex: '1 0 auto',
  width: '',
};

const Index = (props) => {
  const classes = useStyles(props);
  return (
    <Layout>
      <SEO title="Home" />
      <div className={classes.choppingBoardContainer}>
        <ImageChoppingBoard className={classes.choppingBoard} imgStyle={{ filter: 'brightness(50%)', objectPosition: 'center 45%' }} />
        <div className={classes.betaBannerContainer}>
          <div className={classes.betaBanner}>
            <span className={classes.betaBannerText}>
              <span style={{ fontSize: '10px' }}>
                STARTS AT
              </span>
              <br />
              $149 PER MONTH
            </span>
          </div>
        </div>
        <div className={classes.choppingBoardTextContainer}>
          <div className={classes.choppingBoardText}>
            Replace your paper lists with an app that makes your data work for you
          </div>
        </div>
      </div>
      <div className={classes.headerSubtitle}>
        Enterprise level tools, built for your small business
      </div>
      <Card style={styleIncludedToolsCard}>
        <h1 className={classes.header}>
          KounterTop Platform Included Tools
        </h1>
        <div className={classes.includedToolsInner}>
          <ul className={classes.includedToolsItem}>
            <li>
              Streamline Communication
            </li>
          </ul>
          <ul className={classes.includedToolsItem}>
            <li>
              Staff Performance Notes
            </li>
          </ul>
          <ul className={classes.includedToolsItem}>
            <li>
              Issue Intervention
            </li>
          </ul>
          <ul className={classes.includedToolsItem}>
            <li>
              Digital Manager Log
            </li>
          </ul>
          <ul className={classes.includedToolsItem}>
            <li>
              Event Calendar
            </li>
          </ul>
          <ul className={classes.includedToolsItem}>
            <li>
              Handle Urgent Issues in Real Time
            </li>
          </ul>
          <ul className={classes.includedToolsItem}>
            <li>
              Customer Feedback and Issues
            </li>
          </ul>
          <ul className={classes.includedToolsItem}>
            <li>
              Email and Mobile Notifications
            </li>
          </ul>
          <ul className={classes.includedToolsItem}>
            <li>
              Historical Data
            </li>
          </ul>
          <ul className={classes.includedToolsItem}>
            <li>
              Access Control and Permissions
            </li>
          </ul>
          <ul className={classes.includedToolsItem}>
            <li>
              Free Lifetime Updates
            </li>
          </ul>
          <ul className={classes.includedToolsItem}>
            <li>
              Up to 25 Staff
            </li>
          </ul>
        </div>
      </Card>
      <div className={classes.headerSubtitle}>
        Pay for only the modules you need
      </div>
      <div className={classes.featuresContainer}>
        <Card style={styleFeatureCard}>
          <ImageChefHat className={classes.featureIcon} />
          <h1 className={classes.header}>
            Food Prep Management Module
          </h1>
          <div className={classes.featureCardContent}>
            <ul>
              <li>
                Reduce Waste
              </li>
              <li>
                Automatic Food Prep List
              </li>
              <li>
                Food Prep Forecasting
              </li>
              <li>
                Seasonal Demand Levels
              </li>
              <li>
                Recipe Book and Scaling
              </li>
              <li>
                Inventory and Waste Tracking
              </li>
              <li>
                Food Rotation Labels
              </li>
              <li>
                Prevent Theft
              </li>
              <li>
                Staff Production Tracking
              </li>
            </ul>
            <Button onClick={() => navigate('/features#prep')}>
              FULL FEATURE LIST
            </Button>
          </div>
        </Card>
        <Card style={styleFeatureCard}>
          <ImageMoneySafe className={classes.featureIcon} />
          <h1 className={classes.header}>
            Tips, Cash and Drawers Module
          </h1>
          <div className={classes.featureCardContent}>
            <ul>
              <li>
                Tip Out Management
              </li>
              <li>
                Theft and Fraud Detection
              </li>
              <li>
                Fully Customizable
              </li>
              <li>
                Fast, Accurate Drawer Count
              </li>
              <li>
                Custom Closeouts
              </li>
              <li>
                Ledger / Safe Tracking
              </li>
              <li>
                Deposit Verification
              </li>
              <li>
                Custom Reports
              </li>
              <li>
                New Tip Law Compliance
              </li>
            </ul>
            <Button onClick={() => navigate('/features#checkouts')}>
              FULL FEATURE LIST
            </Button>
          </div>
        </Card>
        <Card style={styleFeatureCard}>
          <ImageStaffLocation className={classes.featureIcon} />
          <h1 className={classes.header}>
            Scheduling and Presence Module
          </h1>
          <div className={classes.featureCardContent}>
            <ul>
              <li>
                Real Time Staff Location
              </li>
              <li>
                Labor Consolidation
              </li>
              <li>
                Staff Time and Attendance
              </li>
              <li>
                How Many Staff per Station
              </li>
              <li>
                Compare Scheduled to Actual
              </li>
              <li>
                Equipment Presence Accountability
              </li>
              <li>
                Track Contractors / Visitors
              </li>
            </ul>
            <Button onClick={() => navigate('/features#staff')}>
              FULL FEATURE LIST
            </Button>
          </div>
        </Card>
      </div>
    </Layout>
  );
};

export default Index;
